<template>
  <div>
    <search-header></search-header>
    <div class="w-100" style="background-color:#f4f5f5;">
      <div class="container pt-3 pb-3">
        <div class="row">
           <div class="col-2">
             <div class="rounded bg-white">
               <div v-for="m,i in classList" :key="i" @click="onClassClick(i)" class="p-3 text-center" :class="{'border-dashed-bottom': list.length > i +1, 'text-theme' : current == i}" style="cursor: default;">{{m.text}}</div>
             </div>
           </div>
           <div class="col-10">
             <div class="rounded bg-white p-3" style="min-height: 300px;">
               <ul v-if="!isShowContent">
                 <li v-for="m,j in list" @click="onContentClick(j)" :key="j" :class="{'mt-3': j > 0}"><router-link to="" class="ml-3">{{m.title}}</router-link></li>
               </ul>
               <div v-html="content" v-else></div>
            </div>
           </div>
        </div>
     </div>
    </div>
    <map-footer></map-footer>
  </div>
</template>

<script>

import {
  mapGetters
} from 'vuex'
export default {
  data() {
    return {
      current: 0,
      list:[],
      helpClass:['网站公告','我要找地','我要卖地','网站操作流程'],
      classList: [],
      content: '',
      isShowContent: false,
    };
  },
  computed: {
    ...mapGetters(['dict'])
  },
  watch: {
    'dict.newsClass': 'getClassList'
  },
  methods: {
    onClassClick(i) {
      this.isShowContent = false;
      this.current = i;
      this.getList(this.classList[this.current].id);
    },
    onContentClick(j) {
      this.isShowContent = true;
      const regex = new RegExp(`<img [^>]*src=['"]([^'"]+)[^>]*>`, 'gi');
      let _this = this;
      this.content = this.list[j].content.replace(regex, function(match, capture) {
        var match = match.replace(/(style="(.*?)")|(width="(.*?)")|(height="(.*?)")/ig,'width=800px');
        return capture.includes('http://') || capture.includes('https://')  ? match : match.replace(capture, _this.$global
          .picBaseUrl + capture.replace('../',''))
      });
    },
    getClassList() {
      this.classList = this.dict.newsClass.filter(x=> this.helpClass.includes(x.text));
      if(this.$route.query.title) {
        this.current = this.classList.findIndex(x=>x.text == this.$route.query.title);
        this.current = this.current >=0 ? this.current : 0
      }
      if(this.classList.length > 0) {
        this.getList(this.classList[this.current].id);
      }
    },
    getList(id) {
      this.$http.post(this.$api.news.getList, {param: {
        contentType: id
      },size:50,current:1}).then(res => {
        this.list = res.result.records;
      })
		},
   
  }
}
</script>

<style scoped>
li:before{
  content:' ';width:6px;height:6px;border-radius:50%;
  background:#00cc00;position:absolute;margin-top:10px;}
.border-dashed-bottom {
  border-bottom: 1px dashed #ededed;
}
</style>
